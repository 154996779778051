













































import {Component, Vue} from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";

@Component
export default class GoalList extends Vue {
  public list = [];
  public settings: Settings;
  public actualPage: number;
  public totalItems: number;
  public limit: number;

  public constructor() {
    super();
    this.list = [];
    this.actualPage = 1;
    this.totalItems = 1;
    this.limit = 10;
    this.settings = new Settings();
  }

  public loadGoals(): void {
    this.axios.get(this.settings.settings.host + '/api/goals',{
      params: {
        page: this.actualPage.toString(),
        'order[date_add]': 'desc'
      },
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.totalItems = response.data['hydra:totalItems'];
      this.list = response.data['hydra:member'];
    });
  }

  public deleteGoal(idx, id): void {
    this.axios.delete(this.settings.settings.host + '/api/goals/' + id,{
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.$notify({
        group: 'notify',
        type: 'success',
        text: 'Cel został usuniety'
      });
      this.loadGoals();
    });
  }

  created(): void {
    this.loadGoals();
  }
}
